import React, { useState, useEffect } from 'react';
import Lid from './images/box-lid.png';
import Box from './images/box.png';
import Jump from './images/jump-character.png';

const styles = `
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #FFC0CB;
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.scene {
  position: relative;
  width: 300px;
  height: 600px;
  margin-bottom: 20px;
}

.box {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 200px;
  z-index: 2;
}

.box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.kuku {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 100px;
  height: 150px;
  object-fit: contain;
}

.lid {
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 50px;
  z-index: 3;
}

.jump {
  animation: 3s jump ease-in-out infinite;
}

.move {
  animation: 2s wiggle ease-in-out infinite;
}

.rotating {
  animation: 3s rotating ease-in-out forwards;
}

.rotated {
  transform: translateX(200%) rotate(720deg) translateY(600px);
}

@keyframes wiggle {
  0%, 100% { transform: translateX(-50%) rotate(0deg); }
  25% { transform: translateX(-52%) rotate(-5deg); }
  75% { transform: translateX(-48%) rotate(5deg); }
}

@keyframes rotating {
  0% { transform: translateX(-50%) rotate(0deg); }
  50% { transform: translateX(200%) rotate(360deg) translateY(-100px); }
  100% { transform: translateX(200%) rotate(720deg) translateY(600px); }
}

@keyframes jump {
  0%, 100% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(-400px); }
}

.confetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.confetti-piece {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ffd300;
  top: -10px;
  opacity: 0;
}

.confetti-piece:nth-child(3n+0) { background: #00E0FF; }
.confetti-piece:nth-child(3n+1) { background: #FF3399; }
.confetti-piece:nth-child(3n+2) { background: #22FF00; }

.confetti-piece.animated {
  animation: confetti-fall 3s ease-in-out infinite;
}

@keyframes confetti-fall {
  0% { transform: translateY(0) rotate(0deg); opacity: 1; }
  100% { transform: translateY(100vh) rotate(360deg); opacity: 0; }
}

.speech-bubble-container {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  width: 150px;
  height: 400px;
  pointer-events: none;
}

.speech-bubble {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF;
  border-radius: 20px;
  padding: 10px;
  font-size: 18px;
  color: #FF69B4;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  box-sizing: border-box;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #FFFFFF;
  border-bottom: 0;
  margin-left: -10px;
  margin-bottom: -10px;
}

.speech-bubble.visible {
  opacity: 1;
}

.speech-bubble-container.jumping {
  animation: 3s follow-jump ease-in-out infinite;
}

@keyframes follow-jump {
  0%, 100% { transform: translate(-50%, 0); }
  50% { transform: translate(-50%, -400px); }
}

@media (max-width: 600px) {
  .scene {
    width: 250px;
    height: 500px;
  }

  .box {
    width: 150px;
    height: 150px;
  }

  .lid {
    width: 150px;
    height: 40px;
    bottom: 150px;
  }

  .kuku {
    width: 75px;
    height: 112px;
  }

  .speech-bubble {
    font-size: 14px;
    padding: 8px;
    width: 120px;
  }

  .speech-bubble-container {
    bottom: 112px;
    height: 300px;
  }

  @keyframes jump {
    0%, 100% { transform: translateX(-50%) translateY(0); }
    50% { transform: translateX(-50%) translateY(-300px); }
  }

  @keyframes rotating {
    0% { transform: translateX(-50%) rotate(0deg); }
    50% { transform: translateX(150%) rotate(360deg) translateY(-75px); }
    100% { transform: translateX(150%) rotate(720deg) translateY(450px); }
  }

  @keyframes follow-jump {
    0%, 100% { transform: translate(-50%, 0); }
    50% { transform: translate(-50%, -300px); }
  }
}

@media (max-height: 600px) {
  .App {
    justify-content: center;
  }
}
`;

const Confetti = ({ open }) => {
    const [confetti, setConfetti] = useState([]);

    useEffect(() => {
        if (open) {
            const newConfetti = Array.from({ length: 100 }, (_, i) => ({
                id: i,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 3}s`,
            }));
            setConfetti(newConfetti);
        } else {
            setConfetti([]);
        }
    }, [open]);

    return (
        <div className="confetti">
            {confetti.map((piece) => (
                <div
                    key={piece.id}
                    className="confetti-piece animated"
                    style={{
                        left: piece.left,
                        animationDelay: piece.animationDelay,
                    }}
                />
            ))}
        </div>
    );
};

const HappyBirthdayWebsite = () => {
    const [state, setState] = useState({
        move: 'move',
        jump: '',
        rotated: '',
        rotating: '',
        showMessage: false,
    });

    function animate() {
        if (state.rotated !== 'rotated') {
            setState((prev) => ({
                ...prev,
                rotating: 'rotating',
                showMessage: true,
            }));
            setTimeout(
                () => setState((prev) => ({ ...prev, jump: 'jump' })),
                300
            );
            setTimeout(
                () => setState((prev) => ({ ...prev, rotated: 'rotated' })),
                3000
            );
        } else {
            setState({
                move: 'move',
                jump: '',
                rotated: '',
                rotating: '',
                showMessage: false,
            });
        }
    }

    return (
        <>
            <style>{styles}</style>
            <div className="App">
                <Confetti open={state.jump === 'jump'} />
                <div className="scene">
                    <div
                        className={`speech-bubble-container ${
                            state.jump ? 'jumping' : ''
                        }`}
                    >
                        <div
                            className={`speech-bubble ${
                                state.showMessage ? 'visible' : ''
                            }`}
                        >
                            Happy Birthday! Fia!
                        </div>
                    </div>
                    <img
                        className={`kuku ${state.jump}`}
                        src={Jump}
                        alt="Character"
                    />
                    <button className="box" onClick={animate}>
                        <img src={Box} alt="Gift box" />
                    </button>
                    <img
                        className={`lid ${state.move} ${state.rotating} ${state.rotated}`}
                        src={Lid}
                        alt="Box lid"
                    />
                </div>
            </div>
        </>
    );
};

export default HappyBirthdayWebsite;
